// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZUEbK4XOx", "zD827cN1a", "xx2SpODfs", "XIxoyLNEs", "T_th0IOZM"];

const serializationHash = "framer-HtFrE"

const variantClassNames = {T_th0IOZM: "framer-v-1fgthom", XIxoyLNEs: "framer-v-1nu2fru", xx2SpODfs: "framer-v-v2ub30", zD827cN1a: "framer-v-47ee0u", ZUEbK4XOx: "framer-v-fw2d11"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ZUEbK4XOx", "Variant 2": "zD827cN1a", "Variant 3": "xx2SpODfs", "Variant 4": "XIxoyLNEs", "Variant 5": "T_th0IOZM"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZUEbK4XOx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZUEbK4XOx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1mny87r = activeVariantCallback(async (...args) => {
await delay(() => setVariant("zD827cN1a"), 1)
})

const onAppeartb9ke9 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("xx2SpODfs"), 1000)
})

const onAppear1qtqztc = activeVariantCallback(async (...args) => {
await delay(() => setVariant("XIxoyLNEs"), 350)
})

const onAppear1rz0ymh = activeVariantCallback(async (...args) => {
await delay(() => setVariant("T_th0IOZM"), 1000)
})

const onAppearr0ryh4 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("zD827cN1a"), 350)
})

useOnVariantChange(baseVariant, {default: onAppear1mny87r, T_th0IOZM: onAppearr0ryh4, XIxoyLNEs: onAppear1rz0ymh, xx2SpODfs: onAppear1qtqztc, zD827cN1a: onAppeartb9ke9})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({XIxoyLNEs: {value: transition2}, zD827cN1a: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-fw2d11", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ZUEbK4XOx"} ref={ref ?? ref1} style={{backgroundColor: "rgba(38, 115, 204, 0.18)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({T_th0IOZM: {"data-framer-name": "Variant 5"}, XIxoyLNEs: {"data-framer-name": "Variant 4"}, xx2SpODfs: {"data-framer-name": "Variant 3"}, zD827cN1a: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1su0gc4"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"spfG_oZMR"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgba(102, 115, 204, 0.7) 0%, rgba(38, 48, 115, 0) 100%)"}}/><motion.div className={"framer-1q0it6c"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"ua6Qr3F5C"} style={{backgroundColor: "var(--token-4f0ab6f6-0639-4b68-beb7-fe75a231f671, rgb(31, 39, 95))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HtFrE.framer-75mg73, .framer-HtFrE .framer-75mg73 { display: block; }", ".framer-HtFrE.framer-fw2d11 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-HtFrE .framer-1su0gc4 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); left: -20px; overflow: visible; position: absolute; top: -20px; width: 40px; z-index: 1; }", ".framer-HtFrE .framer-1q0it6c { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-HtFrE.framer-fw2d11 { gap: 0px; } .framer-HtFrE.framer-fw2d11 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-HtFrE.framer-fw2d11 > :first-child { margin-top: 0px; } .framer-HtFrE.framer-fw2d11 > :last-child { margin-bottom: 0px; } }", ".framer-HtFrE.framer-v-47ee0u .framer-1su0gc4 { left: unset; right: -20px; }", ".framer-HtFrE.framer-v-v2ub30 .framer-1su0gc4 { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-HtFrE.framer-v-1nu2fru .framer-1su0gc4 { bottom: -20px; height: var(--framer-aspect-ratio-supported, 50px); top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zD827cN1a":{"layout":["fixed","fixed"]},"xx2SpODfs":{"layout":["fixed","fixed"]},"XIxoyLNEs":{"layout":["fixed","fixed"]},"T_th0IOZM":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCG7liog8m: React.ComponentType<Props> = withCSS(Component, css, "framer-HtFrE") as typeof Component;
export default FramerCG7liog8m;

FramerCG7liog8m.displayName = "Helper/Moving BG 1";

FramerCG7liog8m.defaultProps = {height: 45, width: 182};

addPropertyControls(FramerCG7liog8m, {variant: {options: ["ZUEbK4XOx", "zD827cN1a", "xx2SpODfs", "XIxoyLNEs", "T_th0IOZM"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerCG7liog8m, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})